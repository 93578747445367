import Translations, { getLanguage } from "./v2/i18n/Translations";

// CONSTANTS

const contentGroup = {
  Home: "Home",
  Benefits: "Benefits",
  Process: "How to Start",
  Contact: "Contact",
}

const pageTypes = {
  "/": contentGroup.Home,
  "/en/": contentGroup.Home,
  "/korzysci": contentGroup.Benefits,
  "/en/benefits": contentGroup.Benefits,
  "/proces": contentGroup.Process,
  "/en/how-to-start": contentGroup.Process,
  "/kontakt": contentGroup.Contact,
  "/en/contact": contentGroup.Contact,
};

let localUrl = {};
localUrl[contentGroup.Home] = "/";
localUrl[contentGroup.Benefits] = "/korzysci";
localUrl[contentGroup.Process] = "/proces";
localUrl[contentGroup.Contact] = "/kontakt";

let enUrl = {};
enUrl[contentGroup.Home] = "/en/";
enUrl[contentGroup.Benefits] = "/en/benefits";
enUrl[contentGroup.Process] = "/en/how-to-start";
enUrl[contentGroup.Contact] = "/en/contact";

let analiticalUrl = {};
analiticalUrl[contentGroup.Home] = "/";
analiticalUrl[contentGroup.Benefits] = "/benefits";
analiticalUrl[contentGroup.Process] = "/how-to-start";
analiticalUrl[contentGroup.Contact] = "/contact";

let pageTitles = {};
pageTitles[contentGroup.Home] = "home_meta_title";
pageTitles[contentGroup.Benefits] = "benefits_meta_title";
pageTitles[contentGroup.Process] = "process_meta_title";
pageTitles[contentGroup.Contact] = "contact_meta_title";

// EVENTS

export const virtualPageview = () => {
  const pageType = pageTypes[window.location.pathname];
  console.log(window.location);

  window.dataLayer.push({
    event: 'pp_virtual_pageview',
    URL_original: window.location.pathname + window.location.search + window.location.hash,
    URL_en: enUrl[pageType] + window.location.search + window.location.hash,
    URL_local_language: localUrl[pageType]  + window.location.search+ window.location.hash,
    URL_analytical: analiticalUrl[pageType] + window.location.search + window.location.hash,
    content_group: pageType,
    page_title: Translations[pageTitles[pageType]], 
  });
};

export const pageData = () => {

  window.dataLayer.push({
    event: 'pp_page_data',
    app_version: '1.0.0',
    language_version: getLanguage(),
    enviroment: 'DEV',
    server: "EXPRESSJS",
    brand: 'PH Franchise',
    country: 'PL',
    platform: 'website'
  });
};

// AB TEST

export const abTest = (test_name, test_variant) => {
  var test = {
    event: 'pp_ab_test',
  }
  if (test_name && test_variant) {
    test['test_name'] = test_name;
    test['test_variant'] = test_variant;
  }
  window.dataLayer.push(test);
};

// SHOWCASE - MAIN PAGE

export const showcaseView = (showcase_name) => {
  window.dataLayer.push({
    event: 'pp_showcase_view',
    showcase_name
  });
};

export const showcaseClick = (showcase_name) => {
  window.dataLayer.push({
    event: 'pp_showcase_click',
    showcase_name
  });
};

// FORMS

export const formView = (form_name) => {
  window.dataLayer.push({
    event: 'pp_form_view',
    form_name
  });
};

export const formError = (form_name, error_message) => {
  window.dataLayer.push({
    event: 'pp_form_error',
    form_name,
    error_message
  });
};

export const formSubmitted = (form_name) => {
  window.dataLayer.push({
    event: 'pp_form_submitted',
    form_name,
    consent_terms: 'accepted'
  });
};

// POPUPS
export const popupView = (popup_name) => {
  window.dataLayer.push({
    event: 'pp_view_popup',
    popup_name
  });
};

export const popupClick = (popup_name, button_name) => {
  window.dataLayer.push({
    event: 'pp_view_popup',
    popup_name,
    button_name
  });
};

// ERRORS

export const apiError = (error_message, api) => {
  const date = new Date();

  window.dataLayer.push({
    event: 'pp_api_error',
    error_message,
    api,
    URL_original: window.location.pathname,
    app_version: '1.0.0',
    timestamp: date.toIsoString()
 });
};

// LANGUAGE

export const changeLanguage = (language_version) => {
  window.dataLayer.push({
    event: 'pp_change_language',
     language_version
  });
};

// CLICKS EVENTS

export const click = (button_name) => {
  window.dataLayer.push({
    event: 'pp_click',   
    button_name,
    URL_analytical: analiticalUrl[pageTypes[window.location.pathname]] + window.location.search + window.location.hash,
  });
};

export const clickDownload = (file_name, file_path) => {
  window.dataLayer.push({
    event: 'pp_click_download',
    file_name,
    file_path 
 });
};

export const clickOutboundLink = (outbound_url) => {
  window.dataLayer.push({
    event: 'pp_click_outbound_link',
    outbound_url
 });
 };


export const contactUri = (contact_type, contact_detail) => {
  window.dataLayer.push({
    event: 'pp_contact_uri',
    contact_type,
    contact_detail
  });
};
