import pl from "./amrest-franchise-pl-pl-PL.json";
import en from "./amrest-franchise-pl-en-US.json";
import ua from "./amrest-franchise-pl-uk-UA.json";
import { changeLanguage as piwikChangeLanguage } from "../../PiwikDataLayer";

let language;
switch (window.location.pathname.substring(0,4)) {
  case "/en/":
    language = "en";
    break;
  case "/ua/":
    language = "ua";
    break;
  default:
    language = "pl";
}

function changeLanguage(to) {
  if (language === to) {
    return;
  }
  window.document.querySelector("body").classList.remove(language);
  window.document.querySelector("body").classList.add(to);

  language = to;
  piwikChangeLanguage(language) // piwik changeLanguage
  document.documentElement.lang = language;
}

function getLanguage() {
  return language;
}

const translations = new Proxy([], {
  get: function (target, name) {
    switch (language) {
      case "en":
        return en[name];
      case "ua":
        return ua[name];
      default:
        return pl[name];
    }
  },
});

export default translations;
export { changeLanguage, getLanguage };
