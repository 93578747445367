import { withRouter } from 'react-router-dom';
import { getLanguage } from '../i18n/Translations';

let added = false;
let language = getLanguage();

function FakeRouter({ history }) {

    const switchToUa = (url) => {
        switch (url) {
            case '/':
            case '/en/':
                history.push('/ua/');
                break;
            case '/kontakt': 
            case'/en/contact':
                history.push('/ua/contact');
                break;
            case '/korzysci': 
            case'/en/benefits':
                history.push('/ua/benefits');
                break;
            case '/proces': 
            case'/en/how-to-start':
                history.push('/ua/how-to-start');
                break;

            default:
                break;
        }
    };

    const switchToEn = (url) => {
        switch (url) {
            case '/':
            case '/ua/':
                history.push('/en/');
                break;
            case '/kontakt':
            case '/ua/contact':
                history.push('/en/contact');
                break;
            case '/korzysci':
            case '/ua/benefits':
                history.push('/en/benefits');
                break;
            case '/proces': 
            case'/ua/how-to-start':
                history.push('/en/how-to-start');
                break;

            default:
                break;
        }
    };

    const switchToPl = (url) => {
        switch (url) {
            case '/en/':
            case '/ua/':
                history.push('/');
                break;
            case '/en/contact': 
            case'/ua/contact':
                history.push('/kontakt');
                break;
            case '/en/benefits': 
            case'/ua/benefits':
                history.push('/korzysci');
                break;
            case '/en/how-to-start': 
            case'/ua/how-to-start':
                history.push('/proces');
                break;

            default:
                break;
        }
    };

    if (language !== getLanguage()) {
        added = false;
        language = getLanguage();

        if (language === 'pl') {
            switchToPl(window.location.pathname);
        } else if (language === 'ua') {
            switchToUa(window.location.pathname);
        } else {
            switchToEn(window.location.pathname);
        }
    }

    if (!added) {
        history.listen((location) => {
            if (language === 'en') {
                let url = location.pathname;
                switchToEn(url);
            }
            if (language === 'ua') {
                let url = location.pathname;
                switchToUa(url);
            }            
        });

        added = true;
    }

    return <></>;
}

export default withRouter(FakeRouter);
